<div class="container py-2" id="activity">
    <div class="h-100 p-5 bg-white border rounded-3 shadow">
        <h2 class="display-6 fw-bold text-success">Czynności notarialne</h2>
        <p>Notariusz jest powołany do dokonywania czynności, którym strony są obowiązane lub wyrażą chęć nadać formę
            notarialną. Notariusz w zakresie swoich uprawnień działa jako osoba zaufania publicznego, korzystając z
            ochrony przysługującej funkcjonariuszom publicznym. Czynności notarialne, dokonane przez notariusza zgodnie
            z prawem, mają charakter dokumentu urzędowego.</p>
        <h3 class="text-success">Notariusz dokonuje następujących czynności:</h3>
        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4 pt-4">
            <ng-container *ngFor="let activity of activities">
                <app-activity [activityText]="activity.activityText"></app-activity>
            </ng-container>
        </div>
        <p class="text-success fw-bold">
            Czynności notarialnych notariusz dokonuje tylko w języku polskim. Jeżeli strona czynności notarialnej nie
            zna języka polskiego notariusz może dokonać czynności tylko w obecności tłumacza przysięgłego, który dokona
            przekładu treści na język znany stronie. Wyszukiwarka tłumaczy przysięgłych dostępna jest na stronie
            Ministerstwa Sprawiedliwości:
            <a class="link-success text-decoration-none"
               href="https://arch-bip.ms.gov.pl/pl/rejestry-i-ewidencje/tlumacze-przysiegli/lista-tlumaczy-przysieglych/search.html"
               target="_blank"><i class="fa-solid fa-link"></i> Link</a>
        </p>
    </div>
</div>
