<mat-toolbar color="primary">
    <span><i class="fa-solid fa-link"></i> Linki do stron Państwowych</span>
    <span class="spacer"></span>
    <button mat-icon-button matTooltip="Zamknięcie okna" matTooltipPosition="below" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<div class="container">
    <mat-dialog-content class="mat-typography">
        <div class="p-2">
            <ul>
                <li><a class="link-secondary" href="https://ms.gov.pl" target="_blank">
                    Ministerstwo Sprawiedliwości
                </a>
                </li>
                <li><a class="link-secondary" href="https://ekw.ms.gov.pl/eukw_ogol/menu.do" target="_blank">
                    Przeglądarka ksiąg wieczystych
                </a>
                </li>
                <li><a class="link-secondary" href="https://bydgoszcz.sr.gov.pl" target="_blank">
                    Sąd Rejonowy w Bydgoszczy
                </a>
                </li>
                <li><a class="link-secondary" href="https://rejestry-notarialne.pl" target="_blank">
                    Rejestry Notarialne
                </a>
                </li>
                <li><a class="link-secondary" href="https://ems.ms.gov.pl" target="_blank">
                    Krajowy Rejestr Sądowy
                </a>
                </li>
                <li><a class="link-secondary" href="https://isap.sejm.gov.pl" target="_blank">
                    Internetowy system aktów prawnych
                </a>
                </li>

            </ul>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <div class="pb-4">
            <button mat-raised-button mat-dialog-close color="primary" [mat-dialog-close]="true"
                    matTooltip="Zamknięcie okna" matTooltipPosition="left"><i class="fa-solid fa-rotate-left"></i>
                Zamknij
            </button>
        </div>
    </mat-dialog-actions>
</div>
