<app-navbar></app-navbar>
<div class="wrapper">
    <app-info-box-card [isLoading]="isLoading" [infoBoxCard]="infoBoxCard"></app-info-box-card>
    <app-notary></app-notary>
    <app-office></app-office>
    <app-jumbotron></app-jumbotron>
    <app-activities></app-activities>
    <app-info-box></app-info-box>
    <app-contact></app-contact>
    <app-info-box-card [isLoading]="isLoading" [infoBoxCard]="infoBoxCard"></app-info-box-card>
    <app-map></app-map>
    <app-footer></app-footer>
</div>
