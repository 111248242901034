import {Component, OnInit} from '@angular/core';
import {InfoBoxCard} from "../../shared/models/info-box-card";
import {InfoBoxService} from "../../shared/services/info-box.service";

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

    public isLoading = true;
    public infoBoxCard!: InfoBoxCard;

    constructor(private infoBoxService: InfoBoxService) {
    }

    ngOnInit(): void {
        this.getInfoBoxCard();
    }

    public getInfoBoxCard(): void {
        this.isLoading = true;
        this.infoBoxService.getFirst().subscribe((infoBoxCard: InfoBoxCard): void => {
            this.infoBoxCard = infoBoxCard;
            this.isLoading = false;
        })
    }
}
