import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {User} from "../shared/models/user/user";
import {MediaMatcher} from "@angular/cdk/layout";
import {AuthService} from "../shared/services/auth.service";
import {Router} from "@angular/router";

@Component({
    selector: 'app-secure',
    templateUrl: './secure.component.html',
    styleUrls: ['./secure.component.scss']
})
export class SecureComponent implements OnInit, OnDestroy {

    public isLoading = true;
    public user!: User | null;
    public mobileQuery: MediaQueryList;

    private readonly _mobileQueryListener: () => void;

    constructor(changeDetectorRef: ChangeDetectorRef,
                media: MediaMatcher,
                private authService: AuthService,
                private router: Router) {
        this.mobileQuery = media.matchMedia('(max-width: 600px)');
        this._mobileQueryListener = (): void => changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }

    public ngOnInit(): void {
        this.isLoading = true;
        this.authService.user().subscribe((user: User): void => {
            this.user = user;
            this.isLoading = false;
        });
    }

    public logout(): void {
        this.authService.logout().subscribe((): void => {
            this.router.navigate(['/']).then((): boolean => this.authService.isLogged = false);
            localStorage.clear();
        });
    }

    public ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }
}
