import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {MatIconModule} from "@angular/material/icon";
import {MatCardModule} from "@angular/material/card";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatSelectModule} from "@angular/material/select";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatMenuModule} from "@angular/material/menu";
import {MatListModule} from "@angular/material/list";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule} from "@angular/material/tooltip";
import {MatSliderModule} from "@angular/material/slider";
import {MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {MatRadioModule} from "@angular/material/radio";
import {MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule} from "@angular/material/dialog";
import {MatExpansionModule} from "@angular/material/expansion";
import {MatChipsModule} from "@angular/material/chips";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {MatSortModule} from "@angular/material/sort";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MAT_COLOR_FORMATS, MatColorFormats, NgxMatColorPickerModule} from "@angular-material-components/color-picker";
import {MatButtonToggleModule} from "@angular/material/button-toggle";

const MAT_SNACK_BAR_GLOBAL_CONFIG: MatSnackBarConfig = {
    duration: 4000,
    verticalPosition: 'bottom',
    horizontalPosition: 'center'
};

const MAT_DIALOG_GLOBAL_CONFIG: MatDialogConfig = {
    enterAnimationDuration: '700ms',
    exitAnimationDuration: '500ms',
    disableClose: true,
    hasBackdrop: true
};

const CUSTOM_MAT_TOOLTIP: MatTooltipDefaultOptions = {
    showDelay: 1000,
    hideDelay: 500,
    touchendHideDelay: 1500
};

const CUSTOM_MAT_COLOR_FORMATS: MatColorFormats = {
    display: {
        colorInput: 'hex6'
    }
}

const MATERIAL_MODULES = [
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatToolbarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatSliderModule,
    MatSnackBarModule,
    MatTabsModule,
    MatSlideToggleModule,
    MatRadioModule,
    MatDialogModule,
    MatExpansionModule,
    MatChipsModule,
    MatRippleModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMatColorPickerModule,
    MatButtonToggleModule
];

@NgModule({
    declarations: [],
    exports: [...MATERIAL_MODULES],
    providers: [
        {provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: MAT_SNACK_BAR_GLOBAL_CONFIG},
        {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: MAT_DIALOG_GLOBAL_CONFIG},
        {provide: MAT_COLOR_FORMATS, useValue: CUSTOM_MAT_COLOR_FORMATS},
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: CUSTOM_MAT_TOOLTIP}
    ],
    imports: [
        CommonModule
    ]
})
export class MaterialModule {
}
