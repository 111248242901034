import {AbstractControl, FormGroup} from '@angular/forms';

export function ConfirmedValidator(controlName: string, matchingControlName: string): (formGroup: FormGroup) => void {
    return (formGroup: FormGroup): void => {
        const control: AbstractControl<string, string> = formGroup.controls[controlName];
        const matchingControl: AbstractControl<string, string> = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({confirmedValidator: true});
        } else {
            matchingControl.setErrors(null);
        }
    }
}
