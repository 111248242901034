import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from "../services/auth.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {inject} from "@angular/core";

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
    const authService: AuthService = inject(AuthService);
    const snackbar: MatSnackBar = inject(MatSnackBar);
    const router: Router = inject(Router);

    if (!authService.isLogged) {
        snackbar.open('Access denied, please login.', '', {panelClass: 'snackbar-error'});
        router.navigate(['/login'], {queryParams: {returnURL: state.url}}).then();
    }

    return authService.isLogged;
};
