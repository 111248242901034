<div class="py-2 px-2 py-md-5 px-md-5">
    <div *ngIf="isLoading; else content">
        <mat-spinner></mat-spinner>
    </div>
    <ng-template #content>
        <div class="row align-items-md-stretch g-3">
            <div class="col-md-5">
                <app-info-card [infoBoxCard]="infoBoxCard"></app-info-card>
            </div>
            <div class="col-md-7">
                <app-info-box-card [isLoading]="isLoading" [infoBoxCard]="infoBoxCard"></app-info-box-card>
            </div>
        </div>
    </ng-template>
</div>
