import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from "./shared/material/material.module";
import {PublicModule} from "./public/public.module";
import {AuthModule} from "./auth/auth.module";
import {SecureModule} from "./secure/secure.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {AuthService} from "./shared/services/auth.service";
import {SnackbarService} from "./shared/services/snackbar.service";
import {CredentialInterceptor} from "./shared/interceptors/credential.interceptor";

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MaterialModule,
        PublicModule,
        AuthModule,
        SecureModule,
        HttpClientModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CredentialInterceptor,
            multi: true,
        },
        AuthService,
        SnackbarService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
