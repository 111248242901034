import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {GovernmentLinksComponent} from "./government-links/government-links.component";

@Component({
    selector: 'app-office',
    templateUrl: './office.component.html',
    styleUrls: ['./office.component.scss']
})
export class OfficeComponent {

    constructor(public dialog: MatDialog) {
    }

    public openGovLinkList(): void {
        this.dialog.open(GovernmentLinksComponent);
    }
}
