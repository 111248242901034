import {Component} from '@angular/core';
import {MatDialog} from "@angular/material/dialog";
import {FilesToDownloadComponent} from "../files-to-download/files-to-download.component";

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {

    constructor(public dialog: MatDialog) {
    }

    public openFileList(): void {
        this.dialog.open(FilesToDownloadComponent);
    }
}
