<div class="container py-2" id="contact">
    <div class="row align-items-md-stretch">
        <div class="col-md-8">
            <app-contact-details></app-contact-details>
        </div>
        <div class="col-md-4 pt-2 pt-md-0">
            <app-opening-hours></app-opening-hours>
        </div>
    </div>
</div>
