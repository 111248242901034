import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {SnackbarService} from "../../../shared/services/snackbar.service";
import {InfoBoxService} from "../../../shared/services/info-box.service";

@Component({
    selector: 'app-question-modal',
    templateUrl: './question-modal.component.html',
    styleUrls: ['./question-modal.component.scss']
})
export class QuestionModalComponent {

    public id = 0;
    public checked = false;

    constructor(
        private infoBoxService: InfoBoxService,
        private snackbarService: SnackbarService,
        private dialogRef: MatDialogRef<QuestionModalComponent>,
        @Inject(MAT_DIALOG_DATA)
            data: {
            id: number
        }) {
        this.id = data.id;
    }

    public delete(id: number): void {
        this.infoBoxService.delete(id).subscribe({
            next: () => this.dialogRef.close(),
            error: () => this.snackbarService.snackbarError(),
            complete: () => this.snackbarService.snackbarCustomComplete('Box został skasowany poprawnie')
        });
    }
}
