import {Component, OnInit} from '@angular/core';
import {transition, trigger, useAnimation} from "@angular/animations";
import {fadeIn} from "../../../shared/animations/animations";
import {Slider} from "../../../shared/models/slider";

@Component({
    selector: 'app-jumbotron',
    templateUrl: './jumbotron.component.html',
    styleUrls: ['./jumbotron.component.scss'],
    animations: [
        trigger("fadeIn", [
            transition(":enter", [useAnimation(fadeIn, {params: {time: '900ms'}})]),
        ]),
    ],
})
export class JumbotronComponent implements OnInit {

    imageIndex = 0;

    public jumbotronImages: Slider[] = [
        {
            imgURL: '/assets/images/signature-01.webp',
            slogan: 'Lex est quod notamus'
        },
        {
            imgURL: '/assets/images/signature-02.webp',
            slogan: 'Ius est ars boni et aequi'
        },
        {
            imgURL: '/assets/images/signature-03.webp',
            slogan: 'Lex retro non agit'
        },
    ];

    ngOnInit(): void {
        this.changeImage();
    }

    changeImage(): void {
        setInterval((): void => {
            this.imageIndex += 1;
            if (this.imageIndex > 2) {
                this.imageIndex = 0;
            }
        }, 5000);
    }
}
