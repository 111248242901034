import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute} from "@angular/router";
import {Observable} from "rxjs";
import {User} from "../models/user/user";
import {Login} from "../models/user/login";
import {ApiToken} from "../models/user/api-token";
import {ApiMessage} from "../models/api-message";
import {Email} from "../models/user/email";
import {PasswordReset} from "../models/user/password-reset";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    isLogged = false;

    constructor(protected http: HttpClient,
                private route: ActivatedRoute) {
    }

    // user login
    login(data: Login): Observable<ApiToken> {
        const returnURL: string = this.route.snapshot.queryParamMap.get('returnURL') || '/secure/dashboard';
        localStorage.setItem('returnURL', returnURL);

        return this.http.post<ApiToken>(`${environment.api}/login`, data);
    }

    // get user data
    user(): Observable<User> {
        return this.http.get<User>(`${environment.api}/user`);
    }

    // user send e-email for reset password link
    forgotPassword(data: Email): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/forgot`, data);
    }

    // user set new password
    resetPassword(data: PasswordReset): Observable<ApiMessage> {
        return this.http.post<ApiMessage>(`${environment.api}/reset`, data);
    }

    // user logout
    logout(): Observable<void> {
        return this.http.post<void>(`${environment.api}/logout`, {});
    }
}
