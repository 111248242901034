<div class="container py-2" id="payment">
    <div class="row align-items-md-stretch">
        <div class="col-md-6">
            <app-payment></app-payment>
        </div>
        <div class="col-md-6 pt-2 pt-md-0">
            <app-documents></app-documents>
        </div>
    </div>
</div>
