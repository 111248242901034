<header>
    <nav class="navbar fixed-top bg-light">
        <div class="container mb-2 border-bottom">
            <div class="col-12 col-md-3 mb-2 mb-md-0 text-center">
                <a href="/" class="d-inline-flex link link-body-emphasis text-decoration-none">
                    <img class="my-0 mr-md-auto logo logo-img" src="/assets/images/huse-logo.svg"
                         alt="Logo Notariusz Małgorzata Huse – Strzelecka"
                         title="Logo Notariusz Małgorzata Huse – Strzelecka">
                </a>
            </div>
            <div class="col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                <app-contact-link></app-contact-link>
                <app-nav-link></app-nav-link>
            </div>
        </div>
    </nav>
</header>
