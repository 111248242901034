<div class="container py-2">
    <div class="h-100 border rounded-3 shadow">
        <div class="ratio ratio-16x9">
            <iframe
                style="border:0"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2390.728896925055!2d18.06984621317731!3d53.18684287212767!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470310d597fe0937%3A0xee2b3e470b759b72!2sKancelaria%20Notarialna%20Ma%C5%82gorzata%20Huse%20-%20Strzelecka%20-%20notariusz!5e0!3m2!1spl!2spl!4v1707068998505!5m2!1spl!2spl"
            ></iframe>
        </div>
    </div>
</div>
