import {Component, Input} from '@angular/core';
import {InfoBoxCard} from "../../../shared/models/info-box-card";

@Component({
    selector: 'app-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {

    @Input() public infoBoxCard!: InfoBoxCard;

}
