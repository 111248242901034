import {Component, EventEmitter, Input, Output} from '@angular/core';
import {transition, trigger, useAnimation} from "@angular/animations";
import {scaleOut} from "../../animations/animations";
import {InfoBoxCard} from "../../models/info-box-card";

@Component({
    selector: 'app-info-box-card',
    templateUrl: './info-box-card.component.html',
    styleUrls: ['./info-box-card.component.scss'],
    animations: [
        trigger("scaleOut", [
            transition(":leave", [useAnimation(scaleOut, {params: {time: '800ms'}})]),
        ]),
    ],
})
export class InfoBoxCardComponent {

    @Input() public isLoading = false;
    @Input() public infoBoxCard!: InfoBoxCard;
    @Input() public editMode = false;
    @Output() public deleteInfoBoxCard: EventEmitter<number> = new EventEmitter();

    public boxShow = true;

    public switchBoxShow(): void {
        this.boxShow = !this.boxShow;
    }

    public deleteCard(id: number): void {
        this.deleteInfoBoxCard.emit(id);
    }
}
