import {Component, OnInit} from '@angular/core';
import {AuthService} from "../../shared/services/auth.service";
import {User} from "../../shared/models/user/user";

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

    public user!: User;
    public isLoading = true;

    constructor(private authService: AuthService) {
    }

    ngOnInit(): void {
        this.loadAuthUser();
    }

    public loadAuthUser(): void {
        this.isLoading = true;
        this.authService.user().subscribe((user: User): void => {
            this.user = user;
            this.isLoading = false;
        });
    }
}
