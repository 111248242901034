<div class="d-flex contact-link-box flex-wrap justify-content-md-end justify-content-center">
    <h6 class="display-none"><a class="text-decoration-none" href="mailto:kancelaria@notariuszosielsko.pl"><i
        class="fas fa-envelope text-success"></i>
        <a class="text-decoration-none text-success fw-bold" href="mailto:kancelaria@notariuszosielsko.pl">
            kancelaria@notariuszosielsko.pl</a></a></h6>
    <h6 class="px-2 text-success display-none">|</h6>
    <h6 class="font-height"><a class="text-decoration-none" href="tel:728499788"><i
        class="fas fa-phone text-success"></i>
        <a class="text-decoration-none text-success fw-bold" href="tel:728499788"> 728 499 788</a></a></h6>
</div>
