<mat-toolbar color="primary">
    <span><i class="fa-solid fa-file-arrow-down"></i> Dokumenty do pobrania </span>
    <span class="spacer"></span>
    <button mat-icon-button matTooltip="Zamknięcie okna" matTooltipPosition="below" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<div class="container">
    <mat-dialog-content class="mat-typography">
        <mat-tab-group class="" mat-stretch-tabs="false" mat-align-tabs="start">
            <mat-tab label="Wykaz dokumentów">
                <div class="pt-3">
                    <ul>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentow-najem-okazjonalny.docx"
                               download="wykaz-dokumentow-najem-okazjonalny.docx">
                            najem okazjonalny lokalu mieszkalnego
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentow-sprzedaz-lub-darowizna-lokalu.docx"
                               download="wykaz-dokumentow-sprzedaz-lub-darowizna-lokalu.docx">
                            sprzedaż lub darowizna lokalu z księgą wieczystą
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentow-sprzedaz-lub-darowizna-nieruchomosci-gruntowej.docx"
                               download="wykaz-dokumentow-sprzedaz-lub-darowizna-nieruchomosci-gruntowej.docx">
                            umowa sprzedaży, darowizny lub zamiany nieruchomości
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx"
                               download="wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx">
                            testament
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx"
                               download="wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx">
                            oświadczenie o przyjęciu lub odrzuceniu spadku
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx"
                               download="wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx">
                            umowa majątkowa małżeńska
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx"
                               download="wykaz-dokumentów-testament-akt-poswiadczenia-dziedziczenia-spadek.docx">
                            pełnomocnictwo do sprzedaży nieruchomości
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                    </ul>
                </div>
            </mat-tab>
            <mat-tab label="Formularze danych">
                <div class="pt-3">
                    <ul>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/formularz-danych-do-odrzucenia-lub-przyjecia-spadku.docx"
                               download="formularz-danych-do-odrzucenia-lub-przyjecia-spadku.docx">
                            oświadczenie o przyjęciu lub odrzuceniu spadku
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/formularz-danych-do-pelnomocnictwa.docx"
                               download="formularz-danych-do-pelnomocnictwa.docx">
                            pełnomocnictwo
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/formularz-danych-do-testamentu.docx"
                               download="formularz-danych-do-testamentu.docx">
                            testament
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/formularz-danych-do-umowy-sprzedazy-lub-darowizny.docx"
                               download="formularz-danych-do-umowy-sprzedazy-lub-darowizny.docx">
                            umowa sprzedaży lub darowizny
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                        <li><a class="link-secondary"
                               href="../../../../../assets/documents/formularz-danych-wykaz-do-aktu-dziedziczenia.docx"
                               download="formularz-danych-wykaz-do-aktu-dziedziczenia.docx">
                            akt poświadczenia dziedziczenia
                            <span><i class="fa-solid fa-circle-down mx-2 text-success"></i></span>
                        </a>
                        </li>
                    </ul>
                </div>
            </mat-tab>
        </mat-tab-group>
        <div class="text-center pt-3">
            <p>Dokładny zakres dokumentów wymaganych przy dokonywaniu poszczególnych <br/> czynności notarialnych zależy
                od rodzaju i treści zamierzonej czynności, <br/> stanu prawnego oraz okoliczności faktycznych konkretnej
                sprawy.
            </p>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
        <button mat-raised-button mat-dialog-close color="primary" [mat-dialog-close]="true"
                matTooltip="Zamknięcie okna" matTooltipPosition="left"><i class="fa-solid fa-rotate-left"></i> Zamknij
        </button>
    </mat-dialog-actions>
</div>
