import {Component, EventEmitter, Input, Output} from '@angular/core';
import {User} from "../../../shared/models/user/user";
import {MatSidenav} from "@angular/material/sidenav";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-sidenav-toolbar',
    templateUrl: './sidenav-toolbar.component.html',
    styleUrls: ['./sidenav-toolbar.component.scss']
})
export class SidenavToolbarComponent {

    @Input() public user!: User | null;
    @Input() public sidenav!: MatSidenav;

    @Output() public logout: EventEmitter<void> = new EventEmitter();

    constructor(private titleService: Title) {
    }

    public getTitle(): string {
        return this.titleService.getTitle();
    }

    public logoutEmit(): void {
        this.logout.emit();
    }
}
