import {animate, animation, style} from "@angular/animations";

// inLeft
export const inLeft = animation([
    style({transform: 'translateX(-100%)'}),
    animate('{{time}} ease-out', style({transform: 'translateX(0)'})),
]);

// inRight
export const inRight = animation([
    style({transform: 'translateX(100%)'}),
    animate('{{time}} ease-out', style({transform: 'translateX(0)'})),
]);

// scaleIn
export const scaleIn = animation([
    style({opacity: 0, transform: "scale(0.5)"}),
    animate(
        "{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)",
        style({opacity: 1, transform: "scale(1)"})
    )
]);

// scaleOut
export const scaleOut = animation([
    animate(
        "{{time}} cubic-bezier(0.785, 0.135, 0.15, 0.86)",
        style({opacity: 0, transform: "scale(0.5)"})
    )
]);

// fadeIn
export const fadeIn = animation([
    style({opacity: 0}), // start state
    animate('{{time}}',
        style({opacity: 1}))
]);

// fadeOut
export const fadeOut = animation([
    animate('{{time}}',
        style({opacity: 0}))
]);

// inTop
export const inTop = animation([
    style({transform: 'translateY(-100%)'}),
    animate('{{time}} ease-out', style({transform: 'translateX(0)'})),
]);

// inBottom
export const inBottom = animation([
    style({transform: 'translateY(100%)'}),
    animate('{{time}} ease-out', style({transform: 'translateX(0)'})),
]);
