<div class="h-100 p-5 bg-white border rounded-3 shadow">
    <h3 class="fw-bold text-success">Godziny pracy</h3>
    <div class="pt-4">
        <h5>poniedziałek: <b class="text-success">9:00 - 16:00</b></h5>
        <h5 class="pt-2">wtorek: <b class="text-success">10:00 - 18:00</b></h5>
        <h5 class="pt-2">środa: <b class="text-success">9:00 - 16:00</b></h5>
        <h5 class="pt-2">czwartek: <b class="text-success">9:00 - 16:00</b></h5>
        <h5 class="pt-2">piątek: <b class="text-success">8:00 - 14:00</b></h5>
    </div>
</div>
