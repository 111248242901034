import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AuthComponent} from './auth.component';
import {LoginComponent} from './login/login.component';
import {RouterLink, RouterOutlet} from "@angular/router";
import {SharedModule} from "../shared/shared.module";
import {MaterialModule} from "../shared/material/material.module";
import {ReactiveFormsModule} from "@angular/forms";
import {PasswordResetComponent} from './password-reset/password-reset.component';
import {PasswordComponent} from './password/password.component';
import {AuthTopComponent} from './components/auth-top/auth-top.component';


@NgModule({
    declarations: [
        AuthComponent,
        LoginComponent,
        PasswordResetComponent,
        PasswordComponent,
        AuthTopComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        MaterialModule,
        RouterOutlet,
        ReactiveFormsModule,
        RouterLink,
        NgOptimizedImage
    ]
})
export class AuthModule {
}
