<div class="py-2 px-2 py-md-5 px-md-5">
    <div class="row align-items-md-stretch g-3">
        <div class="col-md-6">
            <div *ngIf="isLoading; else content">
                <mat-spinner></mat-spinner>
            </div>
            <ng-template #content>
                <mat-card class=" mat-elevation-z3">
                    <mat-card-header>
                        <mat-card-title>
                            <mat-icon class="mx-1" color="primary">person</mat-icon>
                            <span>Informacje Profilowe</span>
                        </mat-card-title>
                        <mat-card-subtitle>Informacje o zalogowanym użytkowniku.
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-list>
                            <mat-list-item>
                                <span matListItemTitle>{{ user.name }}</span>
                                <span matListItemLine>nazwa</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>{{ user.email }}</span>
                                <span matListItemLine>adres e-mail</span>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>
            </ng-template>
        </div>
    </div>
</div>
