import {Component} from '@angular/core';
import {Activity} from "../../../shared/models/activity";

@Component({
    selector: 'app-activities',
    templateUrl: './activities.component.html',
    styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent {

    public activities: Activity[] = [
        {
            activityText: 'sporządza akty notarialne'
        },
        {
            activityText: 'sporządza akty poświadczenia dziedziczenia'
        },
        {
            activityText: 'sporządza poświadczenia'
        },
        {
            activityText: 'spisuje protokoły'
        },
        {
            activityText: 'podejmuje czynności dotyczące europejskiego poświadczenia spadkowego'
        },
        {
            activityText: 'sporządza protesty weksli i czeków'
        },
        {
            activityText: 'sporządza wypisy, odpisy i wyciągi dokumentów'
        },
        {
            activityText: 'sporządza inne czynności wynikające z odrębnych przepisów'
        },
        {
            activityText: 'przyjmuje na przechowanie pieniądze, papiery wartościowe, dokumenty, dane na informatycznym nośniku danych'
        },
        {
            activityText: 'sporządza, na żądanie stron, projekty aktów, oświadczeń i innych dokumentów'
        },
        {
            activityText: 'składa wnioski o wpis w księdze wieczystej wraz z dokumentami stanowiącymi podstawę wpisu w księdze wieczystej'
        },
        {
            activityText: 'podejmuje czynności związane z zarządem sukcesyjnym przedsiębiorstwem osoby fizycznej'
        },
    ];
}
