import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import {SnackbarService} from "../../shared/services/snackbar.service";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss', './../auth.component.scss']
})
export class LoginComponent implements OnInit {

    public loginForm: FormGroup = this.formBuilder.group({});

    public emailMaxLength = 70;
    public passwordMinLength = 8;
    public passwordMaxLength = 40;

    constructor(private formBuilder: FormBuilder,
                private authService: AuthService,
                private snackbarService: SnackbarService,
                private router: Router) {
    }

    public ngOnInit(): void {
        this.buildLoginForm();
    }

    public get emailAddress(): AbstractControl {
        return this.loginForm.controls['email'];
    }

    public get password(): AbstractControl {
        return this.loginForm.controls['password'];
    }

    public login(): void {
        this.authService.login(this.loginForm.getRawValue()).subscribe({
            next: (): void => {
                const returnURL: string | null = localStorage.getItem('returnURL');
                this.router.navigateByUrl(returnURL || '/secure/dashboard').then();
            },
            error: () => this.snackbarService.snackbarAuthPassError(),
            complete: (): void => {
                this.authService.isLogged = true;
                this.snackbarService.snackbarCustomComplete('Witamy, zalogowano!');
            }
        });
    }

    private buildLoginForm(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', {validators: [Validators.required, Validators.email, Validators.maxLength(this.emailMaxLength)]}],
            password: ['', {
                validators: [Validators.required,
                    Validators.minLength(this.passwordMinLength), Validators.maxLength(this.passwordMaxLength)]
            }]
        });
    }

}
