<mat-toolbar class="sidenav-mat-toolbar">
    <a href="/" target="_blank">
        <img mat-card-image ngSrc="../../../../assets/images/huse-logo.svg"
             alt="vcX - ToDoList App logo" title="Kancelaria Notarialna Logo"
             height="58" width="245" priority=""></a>
</mat-toolbar>
<mat-divider></mat-divider>
<mat-nav-list>
    <a mat-list-item [routerLinkActive]="['is-active']" routerLink="dashboard"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">dashboard</mat-icon>
            Panel
        </div>
    </a>
    <a mat-list-item [routerLinkActive]="['is-active']" routerLink="info-box"
       (click)="mobileQuery.matches && sidenav.toggle()">
        <div class="mat-list-item-name">
            <mat-icon class="mat-list-item-name__icon" color="primary">featured_video</mat-icon>
            infoBox
        </div>
    </a>
    <mat-divider></mat-divider>
    <mat-action-list>
        <button mat-list-item (click)="logoutEmit()">
            <span class="mat-list-item-name">
                <mat-icon class="mat-list-item-name__icon" color="primary">logout</mat-icon>
                Wyloguj
            </span>
        </button>
    </mat-action-list>
    <div class="sidenav-footer">
        <app-company-link></app-company-link>
    </div>
</mat-nav-list>
