<div class="h-100 p-5 bg-white border text-bg-dark rounded-3 bg-image shadow">
    <h3 class="display-6 lh-1 fw-bold">Dokumenty i dane</h3>
    <p>Każdy osoba biorąca udział w czynności notarialnej powinna posiadać przy sobie <b>ważny dokument tożsamości</b>
        (dowód osobisty, paszport, karta pobytu wydana cudzoziemcowi).</p>
    <p>Pozostałe dokumenty należy dostarczyć do Kancelarii odpowiednio wcześniej przed umówionym terminem dokonania
        czynności.</p>
    <p>Klikając w przycisk poniżej znajdziecie Państwo do pobrania wykaz dokumentów niezbędnych do sporządzenia danego
        aktu notarialnego oraz formularze informacyjne do przygotowania określonej czynności.</p>
    <div class="text-center py-2">
        <button mat-raised-button (click)="openFileList()"><i class="material-icons">file_download</i>Pobierz dokumenty
        </button>
    </div>
</div>
