import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

@Injectable({
    providedIn: 'root'
})
export class SnackbarService {

    constructor(private snackbar: MatSnackBar) {
    }

    public snackbarCustomComplete(message: string): void {
        this.snackbar.open(message, '', {panelClass: 'snackbar-success'});
    }

    public snackbarCustomError(message: string): void {
        this.snackbar.open(message, '', {panelClass: 'snackbar-error'});
    }

    public snackbarAuthPassError(): void {
        this.snackbar.open('Błędny adres e-mail lub nie posiadasz konta.',
            '', {panelClass: 'snackbar-error'});
    }

    public snackbarError(): void {
        this.snackbar.open('Błąd! Coś poszło nie tak.',
            '', {panelClass: 'snackbar-error'});
    }
}
