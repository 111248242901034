<div class="col">
    <mat-card class="mat-elevation-z1">
        <mat-card-header>
            <mat-card-header>
                <mat-card-title>
                    <mat-icon class="mx-2" color="primary">featured_video</mat-icon>
                    <span>infoBox</span>
                </mat-card-title>
            </mat-card-header>
        </mat-card-header>
        <mat-card-content>
            <mat-list-item>
                <span matListItemTitle>Status:</span>
                <span matListItemLine>{{ infoBoxCard.status ? 'Włączony' : 'Wyłączony' }}
                    <i class="fa-solid fa-circle mx-1"
                       [ngClass]="infoBoxCard.status ? 'text-danger': 'text-secondary'"></i>
                </span>
            </mat-list-item>
        </mat-card-content>
        <div class="d-flex justify-content-center py-4">
            <button class="full-width mx-2" mat-raised-button color="primary" routerLink="/secure/info-box"
                    matTooltip="Edytuj treść" matTooltipPosition="below"
            >Edytuj <i class="fa-solid fa-pen-to-square"></i></button>
        </div>
    </mat-card>
</div>
