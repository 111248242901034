<mat-toolbar color="warn">
    <span>Pytanie <i class="fa-solid fa-question"></i></span>
    <span class="spacer"></span>
    <button mat-icon-button matTooltip="Zamknięcie" matTooltipPosition="below" [mat-dialog-close]="true">
        <mat-icon>close</mat-icon>
    </button>
</mat-toolbar>
<mat-dialog-content>
    <div class="container text-center">
        <h4>Czy chcesz skasować box informacyjny?</h4>
        <div class="py-2">
            <mat-checkbox color="primary" [(ngModel)]="checked">Tak</mat-checkbox>
        </div>
    </div>
</mat-dialog-content>
<div class="d-flex justify-content-end pb-4">
    <button class="full-width mx-2" mat-raised-button [mat-dialog-close]="true"
            matTooltip="Wyjście - zamknięcie modalu" matTooltipPosition="below">
        <i class="fa-solid fa-xmark"></i> NIE
    </button>
    <button class="full-width mx-2" mat-raised-button color="warn" (click)="delete(id)"
            matTooltip="Skasowanie boxa" matTooltipPosition="below"
            [disabled]="!checked">TAK <i class="fa-solid fa-check"></i></button>
</div>
