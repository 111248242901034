import {Component, Input, OnInit} from '@angular/core';
import {InfoBoxCard} from "../../../shared/models/info-box-card";
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ColorService} from "../../../shared/services/color.service";
import {Color} from "@angular-material-components/color-picker";
import {RgbColor} from "../../../shared/models/color/rgb-color";

@Component({
    selector: 'app-info-box-form',
    templateUrl: './info-box-form.component.html',
    styleUrls: ['./info-box-form.component.scss']
})
export class InfoBoxFormComponent implements OnInit {

    @Input() public infoBoxCard!: InfoBoxCard;

    public infoBoxCardForm: FormGroup = this.formBuilder.group({});
    public titleMaxLength = 100;
    public textMaxLength = 400;
    public touchUi = false;

    constructor(private formBuilder: FormBuilder,
                private colorService: ColorService) {
    }

    ngOnInit(): void {
        this.buildInfoBoxCardForm();
        if (this.infoBoxCard.id >= 1) this.setInfoBoxCard(this.infoBoxCard);
    }

    public get bg_color(): AbstractControl {
        return this.infoBoxCardForm.controls['bg_color'];
    }

    public get title(): AbstractControl {
        return this.infoBoxCardForm.controls['title'];
    }

    public get text_line1(): AbstractControl {
        return this.infoBoxCardForm.controls['text_line1'];
    }

    public get text_line2(): AbstractControl {
        return this.infoBoxCardForm.controls['text_line2'];
    }

    public get font_color(): AbstractControl {
        return this.infoBoxCardForm.controls['font_color'];
    }

    public get font_size(): AbstractControl {
        return this.infoBoxCardForm.controls['font_size'];
    }

    public get font_weight(): AbstractControl {
        return this.infoBoxCardForm.controls['font_weight'];
    }

    public get start_date(): AbstractControl {
        return this.infoBoxCardForm.controls['start_date'];
    }

    public get end_date(): AbstractControl {
        return this.infoBoxCardForm.controls['end_date'];
    }

    public get status(): AbstractControl {
        return this.infoBoxCardForm.controls['status'];
    }

    public setInfoBoxCard(card: InfoBoxCard): void {
        const {...formData} = card;
        this.infoBoxCardForm.patchValue(formData);
        if (!formData.title) this.title.patchValue('');
        if (!formData.text_line1) this.text_line1.patchValue('');
        if (!formData.text_line2) this.text_line2.patchValue('');
        const bg_color: RgbColor = this.colorService.hexToRgb(card.bg_color);
        const font_color: RgbColor = this.colorService.hexToRgb(card.font_color);
        this.bg_color.setValue(new Color(bg_color.r, bg_color.g, bg_color.b, 1));
        this.font_color.setValue(new Color(font_color.r, font_color.g, font_color.b, 1));
    }

    public setDefault(): void {
        this.bg_color.setValue(new Color(255, 193, 6, 1));
        this.font_color.setValue(new Color(0, 0, 0, 1));
        this.font_size.setValue('1.5rem');
        this.font_weight.setValue('normal');
    }

    private buildInfoBoxCardForm(): void {
        this.infoBoxCardForm = this.formBuilder.group({
            title: ['', {validators: [Validators.maxLength(this.titleMaxLength)]}],
            text_line1: ['', {validators: [Validators.maxLength(this.textMaxLength)]}],
            text_line2: ['', {validators: [Validators.maxLength(this.textMaxLength)]}],
            bg_color: ['', [Validators.required]],
            font_color: ['', [Validators.required]],
            font_size: ['', [Validators.required]],
            font_weight: ['', [Validators.required]],
            start_date: ['2024-02-20'],
            end_date: ['2024-02-20'],
            status: [false]
        });
    }
}
