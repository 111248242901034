<div class="container py-2">
    <div class="image-wrapper rounded-3 shadow">
        <ng-container *ngFor="let image of jumbotronImages; let imageID = index">
            <div *ngIf="imageIndex === imageID" @fadeIn>
                <img class="image img-fluid rounded-3" src="{{ jumbotronImages[imageIndex].imgURL}}"
                     alt="Notariusz Osielsko" title="Notariusz Osielsko">
            </div>
        </ng-container>
        <ng-container *ngFor="let image of jumbotronImages">
            <div class="overlay rounded-3"></div>
            <div class="slogan-overlay fs-4 text-muted mt-1">{{ jumbotronImages[imageIndex].slogan }}</div>
        </ng-container>
    </div>
</div>
