<a class="d-flex flex-row-reverse link-secondary mx-2 mt-2" routerLink="{{ closeLink }}">
    <mat-icon aria-hidden="false" matTooltip="Zamknij" matTooltipPosition="below">close</mat-icon>
</a>
<img mat-card-image class="form-card-logo"
     ngSrc="../../../../assets/images/emblem.svg"
     alt="Kancelaria Notarialna Logo" title="Kancelaria Notarialna Logo"
     height="150" width="150" priority="">
<div class="py-2">
    <h2>{{ title }}</h2>
    <p class="text-secondary">{{ subTitle }}</p>
</div>
