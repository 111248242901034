import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {SecureComponent} from './secure.component';
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {DashboardComponent} from './dashboard/dashboard.component';
import {InfoBoxComponent} from './info-box/info-box.component';
import {SharedModule} from "../shared/shared.module";
import {MaterialModule} from "../shared/material/material.module";
import {SidenavMenuComponent} from './components/sidenav-menu/sidenav-menu.component';
import {SidenavToolbarComponent} from './components/sidenav-toolbar/sidenav-toolbar.component';
import {ProfileComponent} from './profile/profile.component';
import {InfoCardComponent} from './components/info-card/info-card.component';
import {InfoBoxFormComponent} from './info-box/info-box-form/info-box-form.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { QuestionModalComponent } from './components/question-modal/question-modal.component';


@NgModule({
    declarations: [
        SecureComponent,
        DashboardComponent,
        InfoBoxComponent,
        SidenavMenuComponent,
        SidenavToolbarComponent,
        ProfileComponent,
        InfoCardComponent,
        InfoBoxFormComponent,
        QuestionModalComponent
    ],
    exports: [
        InfoBoxComponent
    ],
    imports: [
        CommonModule,
        RouterOutlet,
        SharedModule,
        MaterialModule,
        RouterLinkActive,
        RouterLink,
        NgOptimizedImage,
        ReactiveFormsModule,
        FormsModule
    ]
})
export class SecureModule {
}
