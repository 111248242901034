<div class="py-2 px-2 py-md-5 px-md-5">
    <div *ngIf="isLoading; else content">
        <mat-spinner></mat-spinner>
    </div>
    <ng-template #content>
        <div class="row align-items-md-stretch g-3">
            <div class="col-md-5">
                <mat-card class=" mat-elevation-z34">
                    <mat-card-header class="pb-4">
                        <mat-card-title>
                            <mat-icon class="mx-1" color="primary">featured_video</mat-icon>
                            <span>infoBox</span>
                        </mat-card-title>
                        <mat-card-subtitle>Aktywuj i edytuj box informacyjny.
                        </mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <app-info-box-form [infoBoxCard]="infoBoxCard" #form></app-info-box-form>
                        <div class="d-flex justify-content-end py-3">
                            <button class="full-width mx-2" mat-raised-button routerLink="/secure/dashboard"
                                    matTooltip="Wróć do panelu" matTooltipPosition="below">
                                <i class="fa-solid fa-rotate-left"></i> Wyjdź
                            </button>
                            <button class="full-width" mat-raised-button color="primary" (click)="saveInfoCardBox()"
                                    [disabled]="form.infoBoxCardForm.invalid"
                                    matTooltip="Zapisz treść boxu informacyjnego"
                                    matTooltipPosition="below">Zapisz <i class="fa-solid fa-floppy-disk"></i></button>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-md-7">
                <app-info-box-card [isLoading]="isLoading" [infoBoxCard]="infoBoxCard"
                                   [editMode]="true" (deleteInfoBoxCard)="deleteCardModal($event)">
                </app-info-box-card>
            </div>
        </div>
    </ng-template>
</div>
