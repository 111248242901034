import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {PublicComponent} from './public.component';
import {HomeComponent} from './home/home.component';
import {RouterLink, RouterOutlet} from "@angular/router";
import {NotFoundComponent} from './not-found/not-found.component';
import {MaterialModule} from "../shared/material/material.module";
import {SharedModule} from "../shared/shared.module";
import {NavbarComponent} from './components/navbar/navbar.component';
import {FooterComponent} from './components/footer/footer.component';
import {JumbotronComponent} from './components/jumbotron/jumbotron.component';
import {OfficeComponent} from './components/office/office.component';
import {NotaryComponent} from './components/notary/notary.component';
import {ActivitiesComponent} from './components/activities/activities.component';
import {ActivityComponent} from './components/activities/activity/activity.component';
import {InfoBoxComponent} from './components/info-box/info-box.component';
import {PaymentComponent} from './components/info-box/payment/payment.component';
import {DocumentsComponent} from './components/info-box/documents/documents.component';
import {ContactComponent} from './components/contact/contact.component';
import {OpeningHoursComponent} from './components/contact/opening-hours/opening-hours.component';
import {ContactDetailsComponent} from './components/contact/contact-details/contact-details.component';
import {MapComponent} from './components/map/map.component';
import {ContactLinkComponent} from './components/navbar/contact-link/contact-link.component';
import {NavLinkComponent} from './components/navbar/nav-link/nav-link.component';
import {FilesToDownloadComponent} from './components/info-box/files-to-download/files-to-download.component';
import {GovernmentLinksComponent} from './components/office/government-links/government-links.component';

@NgModule({
    declarations: [
        PublicComponent,
        HomeComponent,
        NotFoundComponent,
        NavbarComponent,
        FooterComponent,
        JumbotronComponent,
        OfficeComponent,
        NotaryComponent,
        ActivitiesComponent,
        ActivityComponent,
        InfoBoxComponent,
        PaymentComponent,
        DocumentsComponent,
        ContactComponent,
        OpeningHoursComponent,
        ContactDetailsComponent,
        MapComponent,
        ContactLinkComponent,
        NavLinkComponent,
        FilesToDownloadComponent,
        GovernmentLinksComponent
    ],
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        RouterOutlet,
        NgOptimizedImage,
        RouterLink
    ]
})
export class PublicModule {
}
