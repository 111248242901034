<div class="text-center py-5">
    <img mat-card-image class="form-card-logo"
         ngSrc="../../../assets/images/emblem.svg"
         alt="Kancelaria Notarialna Logo" title="Kancelaria Notarialna Logo"
         height="150" width="150" priority="">
    <div class="pt-2">
        <h2>Kancelaria Notarialna</h2>
    </div>
    <div class="py-5">
        <h3>Błąd 404</h3>
        <p class="text-secondary">Nie odnaleziono strony lub błędny adres</p>
        <button mat-raised-button color="primary" matTooltip="Zamknij" matTooltipPosition="below" routerLink="/">
            <i class="fa-solid fa-rotate-left"></i> Wróć
        </button>
    </div>
    <div class="mt-2">
        <app-company-link></app-company-link>
    </div>
</div>
