<form [formGroup]="infoBoxCardForm">
    <mat-slide-toggle class="pb-4" formControlName="status"
                      color="primary">{{ status.value ? 'Włączony' : 'Wyłączony' }}
    </mat-slide-toggle>
    <ng-container *ngIf="status.value">
        <mat-form-field class="full-width pt-2" appearance="outline">
            <mat-label>Tytuł:</mat-label>
            <input matInput formControlName="title" name="text" type="text" placeholder="Tytuł"
                   #titleText>
            <mat-hint align="end">
                {{ titleText.value.length }} / {{ titleMaxLength }}
            </mat-hint>
            <mat-error *ngIf="title.value.length > titleMaxLength">
                Maksymalna długość adresu tytułu wynosi: {{ titleMaxLength }} znaków.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Treść Linia 1:</mat-label>
            <textarea matInput formControlName="text_line1" name="text" type="text" placeholder="Treść"
                      #textLine1></textarea>
            <mat-hint align="end">
                {{ textLine1.value.length }} / {{ textMaxLength }}
            </mat-hint>
            <mat-error *ngIf="text_line1.value.length > textMaxLength">
                Maksymalna długość treści wynosi: {{ textMaxLength }} znaków.
            </mat-error>
        </mat-form-field>
        <mat-form-field class="full-width" appearance="outline">
            <mat-label>Treść Linia 2:</mat-label>
            <textarea matInput formControlName="text_line2" name="text" type="text" placeholder="Treść"
                      #textLine2></textarea>
            <mat-hint align="end">
                {{ textLine2.value.length }} / {{ textMaxLength }}
            </mat-hint>
            <mat-error *ngIf="text_line2.value.length > textMaxLength">
                Maksymalna długość treści wynosi: {{ textMaxLength }} znaków.
            </mat-error>
        </mat-form-field>
        <div class="col pt-2">
            <mat-form-field class="me-2" appearance="outline">
                <mat-label>Kolor tła:</mat-label>
                <input matInput [ngxMatColorPicker]="cardPicker" formControlName="bg_color"
                       name="color"
                       type="text"
                       placeholder="Card Color">
                <ngx-mat-color-toggle matSuffix [for]="cardPicker"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #cardPicker [touchUi]="touchUi"></ngx-mat-color-picker>
                <mat-error *ngIf="bg_color.errors?.['required']">
                    Please enter a card color.
                </mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Kolor czcionki:</mat-label>
                <input matInput [ngxMatColorPicker]="fontPicker" formControlName="font_color"
                       name="font_color"
                       type="text"
                       placeholder="Card Color">
                <ngx-mat-color-toggle matSuffix [for]="fontPicker"></ngx-mat-color-toggle>
                <ngx-mat-color-picker #fontPicker [touchUi]="touchUi"></ngx-mat-color-picker>
                <mat-error *ngIf="font_color.errors?.['required']">
                    Please enter a card font color.
                </mat-error>
            </mat-form-field>
        </div>
        <div class="text-center py-2">
            <mat-button-toggle-group formControlName="font_size" name="font_size">
                <mat-button-toggle value="1rem">16px</mat-button-toggle>
                <mat-button-toggle value="1.25rem">20px</mat-button-toggle>
                <mat-button-toggle value="1.5rem">24px</mat-button-toggle>
                <mat-button-toggle value="1.875rem">30px</mat-button-toggle>
            </mat-button-toggle-group>
            <mat-button-toggle-group class="mx-1 mx-md-4" formControlName="font_weight" name="font_weight">
                <mat-button-toggle value="normal">A</mat-button-toggle>
                <mat-button-toggle value="bold"><b>A</b></mat-button-toggle>
            </mat-button-toggle-group>
            <button type="button" class="btn btn-outline-secondary" (click)="setDefault()"
                    matTooltip="Default" matTooltipPosition="below">
                <i class="fa-solid fa-rotate"></i>
            </button>
        </div>
    </ng-container>
</form>
