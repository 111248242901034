import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MatSidenav} from "@angular/material/sidenav";

@Component({
    selector: 'app-sidenav-menu',
    templateUrl: './sidenav-menu.component.html',
    styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent {

    @Input() public sidenav!: MatSidenav;
    @Input() public mobileQuery!: MediaQueryList;

    @Output() public logout: EventEmitter<void> = new EventEmitter();

    public logoutEmit(): void {
        this.logout.emit();
    }
}
