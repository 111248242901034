import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-auth-top',
    templateUrl: './auth-top.component.html',
    styleUrls: ['./auth-top.component.scss']
})
export class AuthTopComponent {

    @Input() public closeLink = '/admin';
    @Input() public title = '';
    @Input() public subTitle = '';

}
