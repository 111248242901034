import {Component, OnInit, ViewChild} from '@angular/core';
import {InfoBoxCard} from "../../shared/models/info-box-card";
import {InfoBoxService} from "../../shared/services/info-box.service";
import {InfoBoxFormComponent} from "./info-box-form/info-box-form.component";
import {SnackbarService} from "../../shared/services/snackbar.service";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {QuestionModalComponent} from "../components/question-modal/question-modal.component";

@Component({
    selector: 'app-info-box',
    templateUrl: './info-box.component.html',
    styleUrls: ['./info-box.component.scss']
})
export class InfoBoxComponent implements OnInit {

    @ViewChild('form') form!: InfoBoxFormComponent;

    public isLoading = false;
    public infoBoxCard!: InfoBoxCard;

    constructor(private infoBoxService: InfoBoxService,
                private snackbarService: SnackbarService,
                private router: Router,
                private dialog: MatDialog) {
    }

    ngOnInit(): void {
        this.getInfoBoxCard();
    }

    public getInfoBoxCard(): void {
        this.isLoading = true;
        this.infoBoxService.getFirst().subscribe((infoBoxCard: InfoBoxCard): void => {
            this.infoBoxCard = infoBoxCard;
            this.isLoading = false;
        })
    }

    public saveInfoCardBox(): void {
        if (this.infoBoxCard.id) this.infoBoxService.update(this.infoBoxCard.id, this.form.infoBoxCardForm.getRawValue()).subscribe({
            next: (): void => this.getInfoBoxCard(),
            error: () => this.snackbarService.snackbarError(),
            complete: (): void => {
                this.snackbarService.snackbarCustomComplete('Ok. Zaktualizowano poprawnie');
                this.router.navigateByUrl('/secure/dashboard').then();
            }
        })
        else this.infoBoxService.create(this.form.infoBoxCardForm.getRawValue()).subscribe({
            next: (): void => this.getInfoBoxCard(),
            error: () => this.snackbarService.snackbarError(),
            complete: (): void => {
                this.snackbarService.snackbarCustomComplete('Ok. Utworzono poprawnie');
                this.router.navigateByUrl('/secure/dashboard').then();
            }
        })
    }

    public deleteCardModal(cardID: number): void {
        this.dialog.open(QuestionModalComponent, {
            data: {
                id: cardID
            }
        }).afterClosed().subscribe(
            (): void => {
                this.getInfoBoxCard();
            }
        );
    }
}
