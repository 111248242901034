<div class="h-100 p-5 bg-white border rounded-3 shadow">
    <h2 class="display-6 fw-bold text-success">Opłaty notarialne</h2>
    <p>Za wykonywane czynności notarialne notariusz pobiera wynagrodzenie (zwane taksą notarialną) według stawek
        określonych w Rozporządzeniu Ministra Sprawiedliwości z dnia 28 czerwca 2004 roku</p>
    <p>Oprócz taksy notarialnej notariusz pobiera, w imieniu państwa polskiego, dodatkowe podatki i opłaty, które
        przekazywane są na konta bankowe odpowiednich instytucji państwowych.</p>
    <mat-expansion-panel (opened)="panelOpenState = true"
                         (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title class="default-font text-success">
                {{ panelOpenState ? 'Są to przede wszystkim:' : 'Czytaj więcej' }}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="default-font">
            <ul class="text-success">
                <li>podatek od czynności cywilnoprawnych</li>
                <li>podatek od spadków i darowizn</li>
                <li>opłaty sądowe (m.in. opłata za dokonanie wpisów w księgach wieczystych)</li>
                <li>podatek VAT</li>
            </ul>
            <p>W celu ustalenia wysokości wszystkich opłat za daną czynność notarialną prosimy o kontakt osobisty, bądź
                - w
                przypadku czynności mniej zawiłych - telefoniczny lub za pośrednictwem poczty elektronicznej.
            </p>
            <p> Uprzejmie informujemy, że płatności dokonywane w kancelarii są płatne w następujący sposób:</p>
            <ul>
                <li>opłata sądowa i podatek PCC muszą być opłacone gotówką, względnie przelewem na podstawie faktury
                    proforma opłaconej przed dokonaniem czynności (w przypadku chęci płatności za pomocą faktury
                    proforma prosimy o zgłoszenie tego faktu minimum 2 dni przed umówionym terminem czynności
                    notarialnej),
                </li>
                <li>pozostałe opłaty (taksa notarialna, opłata za złożenie wniosku wieczystoksięgowego i opłaty za
                    wypisy) mogą być dokonane gotówką, przelewem na podstawie faktury proforma lub kartą.
                </li>
            </ul>
        </div>
    </mat-expansion-panel>
</div>
