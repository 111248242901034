import {Component} from '@angular/core';

@Component({
    selector: 'app-notary',
    templateUrl: './notary.component.html',
    styleUrls: ['./notary.component.scss']
})
export class NotaryComponent {

}
