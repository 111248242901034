import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// public pages
import {HomeComponent} from "./public/home/home.component";

// auth pages
import {AuthComponent} from "./auth/auth.component";
import {LoginComponent} from "./auth/login/login.component";
import {PasswordResetComponent} from "./auth/password-reset/password-reset.component";
import {PasswordComponent} from "./auth/password/password.component";

// secure pages
import {DashboardComponent} from "./secure/dashboard/dashboard.component";
import {InfoBoxComponent} from "./secure/info-box/info-box.component";
import {ProfileComponent} from "./secure/profile/profile.component";

// other pages
import {NotFoundComponent} from "./public/not-found/not-found.component";
import {SecureComponent} from "./secure/secure.component";

// guard
import {authGuard} from "./shared/guard/auth.guard";

const routes: Routes = [

    // public pages
    {path: '', component: HomeComponent},

    // auth pages
    {
        path: '',
        component: AuthComponent,
        children: [
            {path: 'admin', component: LoginComponent, title: "Login"},
            {path: 'password', component: PasswordComponent, title: "Reset hasła"},
            {path: 'password-reset', component: PasswordResetComponent, title: "Zmiana hasła"},
        ],
    },

    // secure pages
    {
        path: 'secure',
        component: SecureComponent,
        canActivate: [authGuard],
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {path: 'dashboard', component: DashboardComponent, title: "Panel"},
            {path: 'info-box', component: InfoBoxComponent, title: "InfoBox"},
            {path: 'profile', component: ProfileComponent, title: "Profil"}
        ],
    },

    // other pages
    {path: '**', component: NotFoundComponent},
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            anchorScrolling: 'enabled',
            scrollOffset: [0, 64] // [x, y]
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
