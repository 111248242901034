import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CompanyLinkComponent} from './components/company-link/company-link.component';
import {InfoBoxCardComponent} from './components/info-box-card/info-box-card.component';
import {MatIconModule} from "@angular/material/icon";
import {MatTooltipModule} from "@angular/material/tooltip";
import {RouterLink} from "@angular/router";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MaterialModule} from "./material/material.module";

@NgModule({
    declarations: [
        CompanyLinkComponent,
        InfoBoxCardComponent
    ],
    exports: [
        CompanyLinkComponent,
        InfoBoxCardComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        MatTooltipModule,
        RouterLink,
        MatProgressSpinnerModule,
        MaterialModule
    ]
})
export class SharedModule {
}
