import {Injectable} from '@angular/core';
import {RgbColor} from "../models/color/rgb-color";

@Injectable({
    providedIn: 'root'
})
export class ColorService {

    public hexToRgb(hex: string): RgbColor {
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        hex = hex.replace(shorthandRegex, (m: string, r, g, b) => {
            return r + r + g + g + b + b;
        });
        const result: RegExpExecArray | null = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16)
        } : {r: 0, g: 0, b: 0};
    }
}
