import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {InfoBoxCard} from "../models/info-box-card";
import {environment} from "../../../environments/environment";
import {InfoBoxCardCreate} from "../models/info-box-card-create";
import {ApiMessage} from "../models/api-message";

@Injectable({
    providedIn: 'root'
})
export class InfoBoxService {

    constructor(protected http: HttpClient) {
    }

    // create InfoBoxCard
    public create(data: InfoBoxCardCreate): Observable<InfoBoxCard> {
        return this.http.post<InfoBoxCard>(`${environment.api}/info-box`, {
            ...data,
            bg_color: '#' + data.bg_color.hex,
            font_color: '#' + data.font_color.hex,
        });
    }

    // get one InfoBoxCard
    public get(id: number): Observable<InfoBoxCard> {
        return this.http.get<InfoBoxCard>(`${environment.api}/info-box/${id}`);
    }

    // get all InfoBoxCards
    public getAll(): Observable<InfoBoxCard[]> {
        return this.http.get<InfoBoxCard[]>(`${environment.api}/info-box`);
    }

    // get first InfoBoxCard
    public getFirst(): Observable<InfoBoxCard> {
        return this.http.get<InfoBoxCard>(`${environment.api}/first`);
    }

    // update InfoBoxCard
    public update(id: number, data: InfoBoxCardCreate): Observable<InfoBoxCard> {
        return this.http.put<InfoBoxCard>(`${environment.api}/info-box/${id}`, {
            ...data,
            bg_color: '#' + data.bg_color.hex,
            font_color: '#' + data.font_color.hex,
        });
    }

    // delete InfoBoxCard
    delete(id: number): Observable<ApiMessage> {
        return this.http.delete<ApiMessage>(`${environment.api}/info-box/${id}`);
    }
}
