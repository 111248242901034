import {Component, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {AuthService} from "../../shared/services/auth.service";
import {Router} from "@angular/router";
import {SnackbarService} from "../../shared/services/snackbar.service";

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styleUrls: ['./password.component.scss', './../auth.component.scss']
})
export class PasswordComponent implements OnInit {

    public passForgotForm: FormGroup = this.formBuilder.group({});

    public isLoading = false;
    public emailMaxLength = 70;

    constructor(private formBuilder: FormBuilder,
                private authService: AuthService,
                private router: Router,
                private snackbarService: SnackbarService) {
    }

    public ngOnInit(): void {
        this.buildPassForgotForm();
    }

    public get emailAddress(): AbstractControl {
        return this.passForgotForm.controls['email'];
    }

    public forgotPassword(): void {
        this.isLoading = true;
        this.authService.forgotPassword(this.passForgotForm.getRawValue()).subscribe({
            next: (): void => {
                this.isLoading = false;
            },
            error: (): void => {
                this.isLoading = false;
                this.snackbarService.snackbarAuthPassError();
            },
            complete: (): void => {
                this.isLoading = false;
                this.router.navigate(['/admin']).then();
                this.snackbarService.snackbarCustomComplete('E-mail został wysłany. Sprawdź skrzynkę.');
            }
        });
    }

    private buildPassForgotForm(): void {
        this.passForgotForm = this.formBuilder.group({
            email: ['', {validators: [Validators.required, Validators.email, Validators.maxLength(this.emailMaxLength)]}]
        });
    }
}
