<div class="h-100 p-5 bg-white border rounded-3 shadow">
    <h2 class="display-6 fw-bold text-success">Kontakt</h2>
    <div class="pt-2">
        <div class="row">
            <div class="col-md-5">
                <h4 class="fw-bold">Kancelaria Notarialna</h4>
                <h5>Małgorzata Huse – Strzelecka Notariusz</h5>
                <h6>ul. Centralna 2X</h6>
                <h6>86-031 Osielsko</h6>
            </div>
            <div class="col-md-6 pt-2 pt-md-0">
                <h4 class="fw-bold">Konto bankowe: </h4>
                <h5>Santander Bank Polska S. A.</h5>
                <h6>24 1090 1896 0000 0001 2425 4876</h6>

                <h4 class="fw-bold">Konto depozytowe: </h4>
                <h5>Santander Bank Polska S. A.</h5>
                <h6>47 1090 1896 0000 0001 3609 5931</h6>
            </div>
        </div>
    </div>
    <div class="pt-4">
        <div class="row">
            <div class="col-md-5">
                <h6><a class="link" href="mailto:kancelaria@notariuszosielsko.pl"><i
                    class="fas fa-envelope text-success"></i>
                    <a class="mx-2 link text-success fw-bold" href="mailto:kancelaria@notariuszosielsko.pl">
                        kancelaria@notariuszosielsko.pl</a></a></h6>
                <h6 class="pt-md-2"><a class="link" href="mailto:notariuszosielsko@gmail.com"><i
                    class="fas fa-envelope text-success"></i>
                    <a class="mx-2 link text-success fw-bold" href="mailto:notariuszosielsko@gmail.com">
                        notariuszosielsko@gmail.com</a></a></h6>
            </div>
            <div class="col-md-6 pt-2 pt-md-0">
                <h4><a class="link" href="tel:728499788"><i class="fas fa-phone text-success"></i>
                    <a class="mx-2 link text-success fw-bold" href="tel:728499788"> 728 499 788</a></a></h4>
                <h4><a class="link" href="tel:728499822"><i class="fas fa-phone text-success"></i>
                    <a class="mx-2 link text-success fw-bold" href="tel:728499822"> 728 499 822</a></a></h4>
            </div>
        </div>
    </div>
</div>
