<div class="container py-2">
    <div class="h-100 p-5 text-bg-success border rounded-3 text-center shadow">
        <img class="my-0 mr-md-auto logo logo-img" src="/assets/images/emblem.svg"
             alt="Notariusz Osielsko"
             title="Notariusz Osielsko">
        <h1 class="mx-2 mx-md-0 display-6 fw-bold">Notariusz Małgorzata Huse – Strzelecka</h1>
        <p>Absolwentka Wydziału Prawa i Administracji Uniwersytetu Mikołaja Kopernika w Toruniu. Ukończyła aplikację
            notarialną prowadzoną przez Izbę Notarialną w Gdańsku z siedzibą w Sopocie zakończoną egzaminem notarialnym
            we wrześniu 2011 roku. Decyzją Ministra Sprawiedliwości z dnia 9 grudnia 2011 roku została powołana na
            stanowisko asesora notarialnego w okręgu Izby Notarialnej w Gdańsku. Doświadczenie zawodowe zdobywała
            pracując jako aplikant notarialny, a następnie asesor notarialny w Kancelarii Notarialnej Anny Mrotek –
            notariusza w Bydgoszczy. Decyzją Ministra Sprawiedliwości z dnia 7 lipca 2014 roku została powołana na
            stanowisko notariusza z wyznaczeniem siedziby w Osielsku. Od 1 października 2014 roku prowadzi Kancelarię
            Notarialną w Osielsku.</p>
    </div>
</div>
