<div class="container py-2" id="office">
    <div class="row align-items-md-stretch">
        <div class="col-md-6">
            <div class="h-100 p-5 bg-white border rounded-3 shadow">
<!--                <div class="text-center">-->
                    <h1 class="mx-2 mx-md-0 display-6 fw-bold text-success">Kancelaria Notarialna</h1>
<!--                    <img ngSrc="assets/images/notariusz.webp" alt="Kancelaria Notarialna" title="Kancelaria Notarialna"-->
<!--                         height="121" width="266">-->
<!--                </div>-->
                <div class="pt-2">
                    <p>Kancelaria Notarialna Małgorzata Huse – Strzelecka – notariusz mieści się na pierwszym piętrze
                        budynku w Osielsku przy ulicy Centralnej 2X. Kancelaria powstała z myślą o mieszkańcach Gminy
                        Osielsko oraz Gminy Dobrcz, ale sąsiedztwo dużego parkingu oraz przystanków linii międzygminnej
                        93
                        pozwala na wygodne dotarcie do kancelarii wszystkim z Państwa, niezależnie od miejsca
                        zamieszkania.
                        Z parkingu (zlokalizowanego przy Polo Markecie) należy kierować się w stronę budynku oddziału
                        Santander Bank i wejść w uliczkę po stronie bankomatu.</p>
                </div>
            </div>
        </div>
        <div class="col-md-6 pt-2 pt-md-0">
            <div class="h-100 p-5 bg-white border rounded-3 shadow">
                <p>Zachęcamy Państwa do wcześniejszego kontaktu z Kancelarią, gdyż umożliwi to uzyskanie wszelkich
                    informacji dotyczących potrzebnej Państwu czynności notarialnej, w szczególności ustalenia dogodnego
                    dla Państwa terminu, ustalenia dokumentów i danych niezbędnych do dokonania czynności oraz uzyskania
                    informacji dotyczących kosztów danej czynności notarialnej, co w rezultacie pozwoli Nam na
                    sprawniejsze załatwienie Państwa sprawy.</p>
                <p class="text-success fw-bold">Udzielanie wszelkich informacji i wyjaśnień w ramach dokonywanej
                    czynności notarialnej jest BEZPŁATNE
                    zarówno w Kancelarii, jak też telefonicznie oraz w formie elektronicznej.</p>
                <p class="text-success fw-bold">W naszej kancelarii uzyskają Państwo informacje w językach polskim,
                    angielskim oraz francuskim.</p>
                <div class="text-center text-md-end">
                    <button mat-stroked-button color="primary" (click)="openGovLinkList()">
                        <i class="fa-solid fa-link"></i> Strony Państwowe
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
