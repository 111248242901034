<div *ngIf="isLoading; else content">
    <div class="d-flex justify-content-center py-2">
        <mat-spinner></mat-spinner>
    </div>
</div>
<ng-template #content>
    <div class="container py-2" *ngIf="infoBoxCard.status && boxShow" @scaleOut>
        <div class="h-100 border rounded-3 text-center shadow" [ngStyle]="{'background-color': infoBoxCard.bg_color}">
            <ng-container *ngIf="!editMode else delete_card">
                <a class="d-flex flex-row-reverse link-secondary mx-2 mt-2" (click)="switchBoxShow()"
                   style="cursor: pointer;">
                    <mat-icon aria-hidden="false" matTooltip="Zamknij" matTooltipPosition="below">close</mat-icon>
                </a>
            </ng-container>
            <ng-template #delete_card>
                <div class="d-flex flex-row-reverse mx-2 mt-2">
                    <button mat-mini-fab color="warn" matTooltip="Usuń"
                            matTooltipPosition="right" (click)="deleteCard(infoBoxCard.id)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
            </ng-template>
            <div class="p-5">
                <h1 class="mx-2 mx-md-0 display-6 fw-bold"
                    [ngStyle]="{'color': infoBoxCard.font_color }">{{ infoBoxCard.title }}</h1>
                <p [ngStyle]="{
                'font-size': infoBoxCard.font_size,
                'font-weight': infoBoxCard.font_weight,
                'color': infoBoxCard.font_color }">
                    {{ infoBoxCard.text_line1 }}
                </p>
                <p [ngStyle]="{
                'font-size': infoBoxCard.font_size,
                'font-weight': infoBoxCard.font_weight,
                'color': infoBoxCard.font_color }">
                    {{ infoBoxCard.text_line2 }}
                </p>
            </div>
        </div>
    </div>
</ng-template>
