import {Component} from '@angular/core';

@Component({
    selector: 'app-government-links',
    templateUrl: './government-links.component.html',
    styleUrls: ['./government-links.component.scss']
})
export class GovernmentLinksComponent {

}
